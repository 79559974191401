<template>
  <backoffice-container>
    <b-card
      no-body
      :class="isMobile ? 'p-2' : 'p-3'"
      :style="
        isMobile
          ? 'min-height: calc(100vh - 100px)'
          : 'min-height: calc(100vh - 125px)'
      "
    >
      <div class="d-flex flex-wrap justify-content-between ml-50 mb-2">
        <h4>{{ $t("backoffice.shipments.title") }}</h4>
        <b-button v-b-modal.new-shipment variant="primary" class="ml-1">
          {{ $t("backoffice.shipments.new-shipment") }}
        </b-button>
      </div>
      <div v-if="isLoading" class="h-100 d-flex align-items-center">
        <b-spinner class="d-block mx-auto mt-3" variant="primary" />
      </div>
      <div v-else>
        <div v-if="items && items.length > 0">
          <div
            v-for="(item, index) in Object.values(filteredItems)"
            v-show="items && items.length > 0"
            :key="index"
            class="w-100"
          >
            <div
              v-if="showSpace && item.sender"
              :class="isMobile ? '' : 'align-items-center'"
              class="d-flex justify-content-between"
            >
              <b-link
                class="text-dark d-flex w-100"
                :class="isMobile ? '' : 'align-items-center'"
                :href="getCollectiveUrl(item.sender)"
                target="_blank"
              >
                <collective-logo :logo="item.sender.logoURL" :size="30" />
                <div class="ml-50">
                  <p class="mb-0 font-weight-bold">
                    {{ translate(item.sender.name) }}
                  </p>
                </div>
              </b-link>
              <div
                class="d-flex"
                :class="
                  isMobile
                    ? 'flex-column justify-content-center'
                    : ' aling-items-center'
                "
              >
                <b-badge
                  variant="light-primary"
                  class="font-weight-normal pointer"
                  :class="isMobile ? 'shipment-1 mt-50 mb-50' : 'shipment-0 mr-50'"
                  @click="downloadShipments(item.sender)"
                >
                  <span v-if="isLoadingButton">
                    <b-spinner small class="spinner" /> {{ $t("loading") }}
                  </span>
                  <span v-else>
                    <feather-icon icon="DownloadIcon" class="mr-25" />
                    {{ $t("backoffice.members.export") }}
                  </span>
                </b-badge>
                <b-badge
                  pill
                  :class="isMobile ? 'shipment-0' : 'shipment-1'"
                  :variant="getColorSpace(item.sender.mainType)"
                  class="text-capitalize check-button"
                >
                  {{ getStatusSpace(item.sender.mainType) }}
                </b-badge>
              </div>
            </div>
            <app-scroll>
              <!-- Shipments table for mobile version -->
              <shipments-table-mobile
                v-if="isMobile"
                :busy="isLoading || isLoadingNextPage"
                :item="item"
                @isSpaceNameShown="(response) => (showSpace = response)"
              />
              <!-- Shipments table for desktop version -->
              <shipments-table-web
                v-else
                :busy="isLoading || isLoadingNextPage"
                :item="item"
              />
            </app-scroll>
          </div>
        </div>
        <div
          v-else
          class="d-flex placeholder align-item-center justify-content-center flex-column my-2"
          style="min-height: 45vh"
        >
          <b-img :src="shipmentsPlaceholder" class="placeholder-img" center />
          <div class="mt-2 text-center">
            <p class="text-primary font-weight-500">
              {{
                $t("available.message", {
                  itemName: $t("backoffice.shipments.title"),
                })
              }}
            </p>
          </div>
        </div>
      </div>
      <loading-modal
        v-model="isLoadingButton"
        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
      />

      <!-- New shipment modal -->
      <b-modal
        id="new-shipment"
        class="shipments-modal"
        :title="$t('backoffice.shipments.new-shipment')"
        :ok-title="$t('form.actions.save')"
        ok-only
        centered
        size="lg"
        :ok-disabled="isDisabled"
        no-close-on-backdrop
        @hidden="resetForm()"
        @shown="atFormShown()"
        @ok="saveShipment()"
      >
        <template #modal-header>
          <language-selector-header
            :title="
              isEditing
                ? $t('backoffice.shipments.edit-shipment')
                : $t('backoffice.shipments.create-shipment')
            "
            @closeModal="closeModal"
            @selectLanguage="(language) => (selectedLanguage = language)"
          />
        </template>
        <!-- Reference and Freight -->
        <b-form-group class="flex-grow-1 mb-2 mt-50">
          <b-row>
            <!-- Reference -->
            <b-col class="col-xs-6">
              <label for="shipment-ref" class="font-weight-bold">
                {{ $t("backoffice.shipments.fields.ref") }}
              </label>
              <b-form-input
                v-model="currentShipment.ref"
                label-for="shipment-ref"
              />
            </b-col>
            <!-- Freight mode -->
            <b-col class="col-xs-6">
              <label for="freight-mode" class="font-weight-bold">
                {{ $t("backoffice.shipments.fields.freight-mode") }}
              </label>
              <v-select
                id="freight-mode"
                v-model="currentShipment.freightMode"
                :options="freightModeOptions"
                :reduce="(option) => option.value"
                label="label"
                placeholder="Select a mode..."
              />
            </b-col>
          </b-row>
        </b-form-group>
        <!-- Incoterms  -->
        <b-form-group 
          class="flex-grow-1 mb-2 mt-50"
        >
          <b-row>
            <!-- Freight prepaid Incoterm -->
            <b-col class="col-xs-6">
              <label for="shipment-freight-prepaid-incoterm" class="font-weight-bold">
                {{ $t("backoffice.shipments.fields.freight-prepaid-incoterm") }}
              </label>
              <v-select
                id="freight-mode"
                v-model="currentShipment.freightPrepaidIncoterm"
                :options="prepaidIncotermOptions"
                :reduce="(option) => option.value"
                label="label"
                placeholder="Select an incoterm..."
              />
            </b-col>
            <!-- Freight collect Incoterm -->
            <b-col class="col-xs-6">
              <label for="shipment-freight-collect-incoterm" class="font-weight-bold">
                {{ $t("backoffice.shipments.fields.freight-collect-incoterm") }}
              </label>
              <v-select
                id="freight-mode"
                v-model="currentShipment.freightCollectIncoterm"
                :options="collectIncotermOptions"
                :reduce="(option) => option.value"
                label="label"
                placeholder="Select an incoterm..."
              />
            </b-col>
          </b-row>
        </b-form-group>
        <!-- Dates -->
        <b-form-group class="flex-grow-1 mb-2 mt-50">
          <b-row>
            <!-- Shipping Date -->
            <b-col class="col-xs-6">
              <label for="shipping-date" class="font-weight-bold">
                {{ $t("backoffice.shipments.fields.shipping-date") }}
              </label>
              <VueCtkDateTimePicker
                id="shipping-date"
                v-model="currentShipment.shippingDate"
                name="shippingDate"
                :locale="locale"
                format="YYYY-MM-DD"
                formatted="ll"
                :label="$t('backoffice.shipments.fields.shipping-date')"
                label-for="shipping-date"
                color="var(--primary)"
                no-button-now
                only-date
                button-color="var(--primary)"
              />
            </b-col>
            <!-- Delivery Date -->
            <b-col class="col-xs-6">
              <label for="delivery-date" class="font-weight-bold">
                {{ $t("backoffice.shipments.fields.delivery-date") }}
              </label>
              <VueCtkDateTimePicker
                id="deliveryDate"
                v-model="currentShipment.deliveryDate"
                name="deliveryDate"
                :locale="locale"
                format="YYYY-MM-DD"
                formatted="ll"
                :label="$t('backoffice.shipments.fields.delivery-date')"
                label-for="delivery-date"
                color="var(--primary)"
                no-button-now
                only-date
                button-color="var(--primary)"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <!-- Sender -->
        <b-form-group>
          <label for="rol-name" class="font-weight-bold">
             {{ $t('backoffice.shipments.fields.sender') }}
          </label>
          <v-select
            v-if="currentCollective.mainType !== 'Organization'"
            v-model="currentShipment.senderKey"
            class="select-control p-0"
            :placeholder="$t('form.select-multiple-placeholder')"
            clearable
            :multiple="false"
            :label="locale"
            :searchable="false"
            :get-option-label="(label) => translate(label.name)"
            :options="senderOrganizationsOptions"
          />
          <div v-else>
            {{ translate(currentCollective.name) }}
          </div>
        </b-form-group>
        <!-- Receiver -->
        <b-form-group>
          <label for="rol-name" class="font-weight-bold">
             {{ $t('backoffice.shipments.fields.receptor') }}
          </label>
          <v-select
            v-model="receiver"
            class="select-control p-0"
            :placeholder="$t('form.select-multiple-placeholder')"
            clearable
            :multiple="false"
            :label="locale"
            :searchable="false"
            :get-option-label="(label) => translate(label.name)"
            :options="receptorOrganizationsOptions"
          />
        </b-form-group>
        <!-- Shipment date -->
        <!--b-form-group>
          <label for="rol-name" class="font-weight-bold">
             {{ $t('backoffice.shipments.fields.issuing-date') }}
          </label>
          
        </b-form-group-->
        <!-- Shipment file -->
        <!--b-form-group>
          <label for="rol-name" class="font-weight-bold">
             {{ $t('backoffice.shipments.fields.file') }}
          </label>
          
        </b-form-group-->
        <!-- Attach file -->
        <b-form-group>
          <label for="rol-name" class="font-weight-bold">
             {{ $t('backoffice.shipments.fields.file') }}
          </label>
          <file-upload
            v-model="attachments"
            class="mw-100"
            cancelable
            type="imageOrPdf"
            :multiple="false"
            :placeholder="$t('backoffice.shipments.fields.attach')"
            :drop-placeholder="$t('backoffice.shipments.fields.drop-placeholder')"
          />
        </b-form-group>
      </b-modal>
    </b-card>
  </backoffice-container>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import BackofficeContainer from "@/backoffice/components/BackofficeContainer.vue";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import ShipmentsPlaceholder from "@/assets/images/placeholders/light/shipments.svg";
import AppScroll from "@/@core/components/app-scroll/AppScroll.vue";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import CollectiveLogo from "@core/components/collective-logo/CollectiveLogo.vue";
import ShipmentsTableWeb from "@/backoffice/components/shipments/ShipmentsTableWeb.vue";
import ShipmentsTableMobile from "@/backoffice/components/shipments/ShipmentsTableMobile.vue";
import { getCollectiveUrl } from "@core/utils/collective";
import LoadingModal from "@core/components/modal/LoadingModal.vue";
import LanguageSelectorHeader from "@core/components/modal/LanguageSelectorHeader.vue";
import vSelect from 'vue-select';
// import Service from '@/config/service-identifiers';
import { MainType } from '@copernicsw/community-common';
import FileUpload from '@core/components/files/FileUpload.vue';

export default {
  name: "BackofficeShipments",
  components: {
    BackofficeContainer,
    AppScroll,
    CollectiveLogo,
    ShipmentsTableWeb,
    ShipmentsTableMobile,
    LoadingModal,
    LanguageSelectorHeader,
    FileUpload,
    vSelect
  },
  directives: {
    Ripple,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      isLoading: false,
      isLoadingNextPage: false,
      isLoadingButton: false,
      showSpace: true,
      receiver: null,
      attachments: null,
      currentShipment: {
        ref: null,
        senderType: "community",
        senderKey: null,
        receiverType: "community",
        receiverKey: null,
        mainCommunitySlug: null,
        status: "succeeded",  //succeeded, pending, failed, refunded, canceled, requires_action, processing, requires_shipment_method
        createdByUserId: null,
        freightMode: null,
        freightPrepaidIncoterm: null,
        freightCollectIncoterm: null,
        shippingDate: null,
        deliveryDate: null
      },
      statusOptions: {
        'succeeded': 'Succeeded',
        'pending': 'Pending',
        'processing': 'Processing',
        'failed': 'Failed',
        'refunded': 'Refunded',
        'canceled': 'Cancelled',
        'requires_action': 'Requires action',
        'requires_shipment_method': 'Requires shipment method'
      },
      billingTypeOptions: {
        'company': 'Company',
        'particular': 'Particular'
      },
      isValid: null,
      selectedLanguage: this.currentLocale,
      isEditing: false,
      freightModeOptions: [
        { value: 0, label: 'Sea Freight' },
        { value: 1, label: 'Air Freight' },
        { value: 2, label: 'Road Freight' },
        { value: 3, label: 'Rail Freight' },
      ],
      prepaidIncotermOptions: [
        { value: 'cfr', label: 'CFR (Cost and Freight)' },
        { value: 'cif', label: 'CIF (Cost, Insurance, Freight)' },
        { value: 'dap', label: 'DAP (Delivered at Place)' },
        { value: 'ddp', label: 'DDP (Delivery Duty Paid)' },
      ],
      collectIncotermOptions: [
        { value: 'fob', label: 'FOB (Free on Board)' },
        { value: 'exw', label: 'EXW (EX Works)' },
      ]
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective?.slug ? this.$store.getters.currentCollective : null;
    },
    mainCollective() {
      return this.$store.getters.mainCollective?.slug ? this.$store.getters.mainCollective : null;
    },
    mainSpace() {
      return this.mainCollective ?? this.currentCollective;
    },
    shipmentsPlaceholder() {
      return ShipmentsPlaceholder;
    },
    itemsData() {
      // console.log('this.$store.getters.shipments', this.$store.getters.shipments);
      // console.log('this.currentCollective.key', this.currentCollective.key);
      // console.log('this.$store.getters.shipments?.[this.currentCollective.key]', this.$store.getters.shipments?.[this.currentCollective.key]);
      return this.$store.getters.shipments?.[this.currentCollective.key];
    },
    items() {
      return this.itemsData?.unpaginated;
    },
    filteredItems() {
      const filteredItems = {};
      this.items?.forEach((item) => {
        if (item.sender?.key) {
          if (!Object.keys(filteredItems).includes(item.sender.key)) {
            filteredItems[item.sender.key] = {
              sender: item.sender,
              shipments: [],
            };
          }
          filteredItems[item.sender.key].shipments.push(item);
        } else {
          console.log("This item does not have a linked sender:", item);
        }
      });
      return filteredItems;
    },
    isMobile() {
      return window.innerWidth < 700;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    senderOrganizationsOptions() {
      const allOrgs = this.$store.getters.communitiesOthers?.organizations?.unpaginated;
      if(allOrgs && this.currentCollective?.mainType === 'Organization'){
        // Select the current org key
        this.currentShipment.senderKey = this.currentCollective.key;
        // Map all orgs to send only the current
        // console.log('senderOrganizationsOptions', [this.currentCollective]);
        return [this.currentCollective];
      }
      return allOrgs;
    },
    receptorOrganizationsOptions() {
      const allOrgs = this.$store.getters.communitiesOthers?.organizations?.unpaginated;
      if(allOrgs && this.currentCollective?.mainType === 'Organization'){
        const allOrgsWithoutCurrent = [];
        allOrgs.forEach(org => {
          if(org.key !== this.currentCollective.key){
            allOrgsWithoutCurrent.push(org);
          }
        });
        return allOrgsWithoutCurrent;
      }
      // console.log('receptorOrganizationsOptions', allOrgs);
      return allOrgs;
    },
    isDisabled() {
      return false;
    },
    modelOptions() {
      const modelTypesObj = this.$store.getters.modelTypes;
      const notApply = [
        'chat-individual',
        'chat-group',
        'chat',
        'map',
        'calendar',
        'meeting',
        'auditorium',
        'workspace',
        'community',
        'analytic',
        'shipment',
        'classifier',
        'custom-field',
        'live-streaming'
      ];
      let newObj = [];
      //use only correct apps
      modelTypesObj.forEach((app) => {
        if(!notApply.includes(app.addonSingular)){
          newObj.push(app);
        }
      });
      return newObj;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  async created() {
    //console.log('this.mainSpace', this.mainSpace);
    await this.fetchData();
    await this.fetchOrganizations();  //TODO: better on open modal
    //console.log('this.organizationsOptions', this.organizationsOptions);
    // If it is an org:
    // console.log('this.currentCollective?.mainType', this.currentCollective?.mainType);
    if(this.currentCollective?.mainType === 'Organization'){
      // console.log('Setting fromOrgKey to current space key')
      this.currentShipment.senderKey = this.currentCollective.key;
    }
  },
  methods: {
    async fetchData(force = false) {
      this.isLoading = true;
      const requestConfig = {};
      if (this.currentCollective.parentKey) {
        requestConfig.forCommunity = true;
      } else {
        requestConfig.forMain = true;
      }
      await this.$store.dispatch("getItems", {
        itemType: "shipments",
        storedKey: this.currentCollective.key,
        page: 1,
        perPage: 20,
        forceAPICall: force,
        requestConfig,
      });
      this.isLoading = false;
    },
    translate(field) {
      return translateTranslationTable(this.currentLocale,field);
      //return translateTranslationTable(this.selectedLanguage, field);
    },
    async downloadShipments(community) {
      this.isLoadingButton = true;
      const response = await this.$store.dispatch("exportFiles", {
        itemType: "/backoffice/shipments/export",
        requestConfig: {
          communitySlug: community.slug,
        },
      });
      setTimeout(this.csv(response, community), 10000);
      this.isLoadingButton = false;
    },
    csv(response, community) {
      const hiddenElement = document.createElement("a");
      hiddenElement.href = response.data.file;
      hiddenElement.target = "_blank";
      hiddenElement.download = `${this.translate(community.name)}Shipments.csv`;
      hiddenElement.click();
    },
    getMainType(mainTypeId) {
      // console.log('mainTypeId', mainTypeId);
      // console.log('getMainType MainType[mainTypeId]', MainType[mainTypeId]);
      return MainType[mainTypeId];
    },
    getStatusSpace(mainType) {
      // console.log('mainType', mainType);
      if(!mainType){
        return 'Space';
      }
      const type = {
        Community: this.$t("collectives.items.Community"),
        Subcommunity: this.$t("collectives.items.Subcommunity"),
        Event: this.$t("collectives.items.Event"),
        Section: this.$t("collectives.items.Section"),
        Course: this.$t("collectives.items.Course"),
        Workspace: this.$t("collectives.items.Workspace"),
        Challenge: this.$t("collectives.items.Challenge"),
        Organization: this.$t("collectives.items.Organization"),
        Service: this.$t("collectives.items.Service"),
      };
      return type[mainType][0].toUpperCase() + type[mainType].substring(1);
    },
    getColorSpace(shipment) {
      if(!shipment){
        return "info";
      }
      const color = {
        0: "light",
        1: "light-warning",
        2: "warning",
        3: "light-success",
        4: "light-danger",
        5: "light-dark",
        6: "secondary",
        7: "light-info",
        8: "info",
        81: "dark",
        Community: "primary",
        Subcommunity: "light-info",
        Event: "danger",
        Section: "success",
        Course: "info",
        Workspace: "dark",
        Challenge: "secondary",
        Organization: "light-info",
        Service: "light-warning",
      };
      return color[shipment];
    },
    getCollectiveUrl(collective) {
      return `${getCollectiveUrl(collective)}/welcome`;
    },
    resetForm() {
      this.currentShipment = {
        ref: null,
        senderType: "community",
        senderKey: null,
        receiverType: "community",
        receiverKey: null,
        mainCommunitySlug: null,
        status: "succeeded",  //succeeded, pending, failed, refunded, canceled, requires_action, processing, requires_shipment_method
        createdByUserId: null,
        freightMode: null,
        freightPrepaidIncoterm: null,
        freightCollectIncoterm: null,
        shippingDate: null,
        deliveryDate: null
      };
      this.isValid = null;
    },
    closeModal() {
      this.$bvModal.hide("new-shipment");
    },
    atFormShown() {
      return;
    },
    async saveShipment() {
      try {
        this.currentShipment.receiverKey = this.receiver?.key;
        this.currentShipment.mainSpaceSlug = this.mainSpace.slug;
        this.currentShipment.mainCommunitySlug = this.mainSpace.slug;
        this.currentShipment.freightMode ? this.currentShipment.freightMode.value : null;
        if(this.currentShipment.senderKey?.key){
          // It is an object, we need to save only the key
          this.currentShipment.senderKey = this.currentShipment.senderKey.key;
        }
        // console.log('creating shipment with currentShipment data:', this.currentShipment)
        await this.$store.dispatch("createItem", {
          item: {
            itemType: "/shipments",
            customName: "shipments",
            requestConfig: {
              ...this.currentShipment,
              //...shipment,
            },
          },
          file: this.attachments,
        });
        this.notifySuccess(
          this.$t("backoffice.shipments.messages.create-success")
        );
      } catch {
        this.notifyError(this.$t("backoffice.shipments.messages.create-error"));
      }
      //TODO: Delete next when the previous works fine...
      window.location.reload();
      // await this.fetchData();  // si vamos a refrescar, para qué pedir los datos de nuevo...
    },
    getLabel(labelObj) {
      return translate(labelObj.name);
    },
    getOptionsLabel() {
      // Only for organizations
      return this.organizationsOptions.map((organization) => this.translate(organization.name));
      //return this.organizationsOptions.filter((organization) => this.translate(organization.name));

      // General:
      // if(!this.modelOptions || this.modelOptions.length <= 0){
      //   return null;
      // }
      // return (a) => a.addon[this.currentLocale] || Object.values(a.addon)[0];
    },
    async fetchOrganizations() {
      // console.log('Fetching organizations with communities simply endpoint')
      //this.currentType = this.typeKey ? this.typeKey : this.communitySlug;
      const organizationName = this.currentCollective.mainType === "Organization" ? this.currentCollective.parentCollective.mainCommunityID ?  this.currentCollective.parentCollective.slug : this.mainSpace.slug : this.currentCollective.slug
      const requestConfig = {
          communityParentSlug: organizationName,
          communitySlug: organizationName,
          transactionByDate: -1,
          checkStatus: true,
          isOrganization: true,
          avoidRedis: true,
          avoidShared: true,
          mainTypeIDs: 9,
          getMinimalData: true,
        };
        // console.log('requestConfig', requestConfig);
      return await this.$store.dispatch('getItems', {
        itemType: "communities/simply",
        customName: "communitiesOthers",
        storedKey: "organizations",
        page: 1,
        forceAPICall: true,
        perPage: 100,
        requestConfig: requestConfig,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.placeholder-img {
  width: 180px;
  display: block;
}

.shipments-modal {
  max-width: 90% !important;
  width: 90% !important;
}
</style>
